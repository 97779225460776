import { isAvailableCountry } from 'constants/country-locale-list';
import { useFlags } from "flagsmith/react";
import Header from "organisms/Header";
import { getUserIpDetail } from 'services/locale.service';



export default function Home() {
  const flags = useFlags(["show_header"]);
  const showHeader = flags.show_header.enabled;


  return (

    <div className="text-center">
      <Header type="homeScreen" />
      <div className="bg-primary-200 h-[100dvh]" />
    </div>
  );
}


export const getServerSideProps = async ({ req, res }) => {
  const countryFromCookies = req.cookies.userCountry;
  const lang = req.cookies.userLocale || 'en';

  if (isAvailableCountry(countryFromCookies)) {
    return {
      redirect: { destination: `/${lang}/${countryFromCookies.toLowerCase()}`, permanent: false }
    }
  }

  const ipData = await getUserIpDetail();
  const country = ipData?.country_code;

  if (isAvailableCountry(country)) {
    res.setHeader('Set-Cookie', `userCountry=${country}; Path=/`);

    return {
      redirect: { destination: `/${lang}/${country.toLowerCase()}`, permanent: false }
    }
  }
  return {
    props: {
      ipData
    }
  }
}